<template>
  <div
    class="choose-variations-tag"
    :class="{
      'choose-variations-tag--loyalty-pricing mt-1': loyaltyPricing,
    }"
  >
    <span class="tp-text-body-2" :class="priceTextClass">
      {{ $t('components.menuItem.priceFrom', { price: lowPriceFormatted }) }}
    </span>
    <div v-if="loyaltyPricing">
      <img
        v-if="$store.getters.customizationLoyaltyCardIcon"
        class="ml-1"
        style="width: 16px;"
        :src="$store.getters.customizationLoyaltyCardIcon"
      />
      <v-icon
        v-else
        small
        :color="$store.getters.customizationPrimaryColor || 'primary'"
        class="ml-1"
      >
        mdi-credit-card-fast-outline
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lowPrice: {
      type: Number,
      required: true,
    },
    loyaltyPricing: {
      type: Boolean,
      default: false,
    },
    bigger: {
      type: Boolean,
      default: false,
    },
    customFontFamily: {
      type: [String, Boolean],
      default: null,
    },
    customFontWeight: {
      type: [String, Boolean],
      default: null,
    },
    customFontColor: {
      type: [String, Boolean],
      default: null,
    },
    customFontSize: {
      type: [String, Boolean],
      default: null,
    },
  },
  computed: {
    lowPriceFormatted() {
      return this.$store.getters.getFormattedPrice(this.lowPrice);
    },
    priceTextClass() {
      return {
        'tp-text-body-2': !this.bigger,
        'tp-text-subtitle-semibold': this.bigger,
        'special-deal': this.price < this.oldPrice,
        'custom-font-family': this.customFontFamily !== false,
        'custom-font-weight': this.customFontWeight !== false,
        'custom-font-color': this.customFontColor !== false,
        'custom-font-size': this.customFontSize !== false,
        'custom-font-style': this.customFontStyle !== false,
      };
    },
  },
};
</script>

<style lang="scss">
.choose-variations-tag {
  display: flex;
  justify-content: center;
  align-items: center;

  &--loyalty-pricing {
    * {
      color: var(--color-primary) !important;
    }
  }
}
</style>
