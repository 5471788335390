<template>
  <div
    v-intersect="{
      handler: trackMenuItemView,
      options: {
        threshold: [1.0],
      },
    }"
    class="menu-item"
    :class="{
      'menu-item--out-of-stock': !in_stock || displayAsOutOfStock,
      'menu-item--in-cart': menuItemIsInCart,
      'menu-item--skip-font-color-customization': skipFontColorCustomization,
      'menu-item--with-divider': enableMenuItemsDivider,
      'menu-item--with-divider--not-first':
        enableMenuItemsDivider && skipFirstItemDivider,
    }"
    @click="openSheet"
  >
    <div
      :class="{
        'menu-item__info--full-width': fullWidthInfo && !photo,
        'pr-3': !fullWidthInfo,
      }"
      class="menu-item__info"
    >
      <div>
        <div class="menu-item-info__title-wrapper">
          <div
            v-if="menuItemIsInCart"
            class="menu-item-info__order-quantity-indicator tp-text-body-white"
          >
            {{ cartQuantity }}
          </div>
          <div class="menu-item-info__title tp-text-subtitle-semibold">
            {{ $t(name) }}
          </div>
        </div>
        <div
          v-if="
            description ||
              dishTagsDefaultAndCustomCombined.length > 0 ||
              !in_stock | displayAsOutOfStock
          "
          class="menu-item-info__description tp-text-body-gray"
        >
          <dish-tags-list
            v-if="
              dishTagsDefaultAndCustomCombined.length > 0 &&
                in_stock &&
                !displayAsOutOfStock
            "
            :tags="dishTagsDefaultAndCustomCombined"
          ></dish-tags-list>
          <span
            v-if="in_stock && !displayAsOutOfStock"
            :style="
              $store.getters
                .customizationMenuShortDescriptionRotatingCategoryColorsEnabled
                ? { color: rotatingCategoryColor }
                : {}
            "
            v-html="$t(descriptionComputed)"
          ></span>
          <span v-else>{{ $t('components.menuItem.outOfStock') }}</span>
        </div>
      </div>
      <div
        class="menu-item-info__price-wrapper"
        :class="{
          'menu-item-info__price-wrapper--align-end ml-5':
            fullWidthInfo && !photo,
          'menu-item-info__price-wrapper--align-self-center':
            $store.getters.customizationMenuItemsPriceVerticalAlignCenter,
        }"
      >
        <div>
          <div
            class="d-flex flex-row align-center justify-space-between"
            :class="{ 'mt-2': !fullWidthInfo }"
          >
            <div class="d-flex flex-row align-center">
              <VariationPrice
                v-if="hasVariations"
                :low-price="variationsLowPrice"
                :bigger="fullWidthInfo"
                :custom-font-family="
                  $store.getters.customizationMenuItemsPriceFontFamily ||
                    $store.getters.customizationMenuFontOverrideFamily
                "
                :custom-font-weight="
                  $store.getters.customizationMenuItemsPriceFontWeight
                "
                :custom-font-color="
                  !($store.getters.customizationMenuDarkMode && inSearchPage) &&
                    $store.getters.customizationMenuItemsPriceFontColor
                "
                :custom-font-size="
                  $store.getters.customizationMenuItemsPriceFontSize
                "
                :custom-font-style="
                  $store.getters.customizationMenuItemsPriceFontStyle
                "
              />
              <price
                v-else
                :price="priceComputed"
                :old-price="oldPriceComputed"
                :bigger="fullWidthInfo"
                :custom-font-family="
                  $store.getters.customizationMenuItemsPriceFontFamily ||
                    $store.getters.customizationMenuFontOverrideFamily
                "
                :custom-font-weight="
                  $store.getters.customizationMenuItemsPriceFontWeight
                "
                :custom-font-color="
                  !($store.getters.customizationMenuDarkMode && inSearchPage) &&
                    $store.getters.customizationMenuItemsPriceFontColor
                "
                :custom-font-size="
                  $store.getters.customizationMenuItemsPriceFontSize
                "
                :custom-font-style="
                  $store.getters.customizationMenuItemsPriceFontStyle
                "
              ></price>

              <popular-badge
                v-if="popular && !fullWidthInfo"
                class="ml-2"
                :bigger="false"
              ></popular-badge>
            </div>
          </div>
          <div
            v-if="loyaltyPricingEnabled"
            class="d-flex flex-row align-center justify-space-between"
          >
            <VariationPrice
              v-if="hasVariations"
              :low-price="variationsLoyaltyLowPrice"
              :loyalty-pricing="true"
              :bigger="fullWidthInfo"
              :custom-font-family="
                $store.getters.customizationMenuItemsPriceFontFamily ||
                  $store.getters.customizationMenuFontOverrideFamily
              "
              :custom-font-weight="
                $store.getters.customizationMenuItemsPriceFontWeight
              "
              :custom-font-color="
                !($store.getters.customizationMenuDarkMode && inSearchPage) &&
                  $store.getters.customizationMenuItemsPriceFontColor
              "
              :custom-font-size="
                $store.getters.customizationMenuItemsPriceFontSize
              "
              :custom-font-style="
                $store.getters.customizationMenuItemsPriceFontStyle
              "
            />
            <loyalty-price
              v-else
              :price="loyalty_price"
              :bigger="fullWidthInfo"
              :custom-font-family="
                $store.getters.customizationMenuItemsPriceFontFamily ||
                  $store.getters.customizationMenuFontOverrideFamily
              "
              :custom-font-weight="
                $store.getters.customizationMenuItemsPriceFontWeight
              "
              :custom-font-size="
                $store.getters.customizationMenuItemsPriceFontSize
              "
              :custom-font-style="
                $store.getters.customizationMenuItemsPriceFontStyle
              "
            ></loyalty-price>
          </div>
        </div>
        <popular-badge
          v-if="popular && fullWidthInfo && !photo"
          class="mt-2"
          :bigger="false"
        ></popular-badge>
      </div>
    </div>
    <div v-if="thumbnail || photo" class="menu-item__image">
      <LazyImage :img="thumbnail || photo" :lazy-img="lqip"></LazyImage>
    </div>
  </div>
</template>

<script>
import Price from '@/components/MenuItem/Price.vue';
import LoyaltyPrice from '@/components/MenuItem/LoyaltyPrice.vue';
import PopularBadge from '@/components/MenuItem/PopularBadge.vue';
import DishTagsList from '@/components/MenuItem/DishTagsList.vue';
import VariationPrice from '@/components/MenuItem/VariationPrice.vue';
import LazyImage from '../LazyImage.vue';
import { normalizeNewLines } from '@/utils/strings';

export default {
  components: {
    PopularBadge,
    Price,
    LoyaltyPrice,
    DishTagsList,
    VariationPrice,
    LazyImage,
  },
  props: {
    id: Number,
    name: String,
    description: String,
    photo: String,
    thumbnail: String,
    lqip: String,
    popular: Boolean,
    price: Number,
    discounted_price: [Number, Boolean],
    loyalty_price: [Number, Boolean],
    tags: Array,
    restaurant_tags: Array,
    is_special_deal: [Boolean],
    variations: Array,
    fullWidthInfo: Boolean,
    in_stock: Boolean,
    displayAsOutOfStock: {
      type: Boolean,
      default: false,
    },
    option_groups: Array,
    category: String,
    categoryHash: String,
    inSearchPage: Boolean,
    rotatingCategoryColor: String,
  },
  data() {
    return {
      sheet: false,
      hasVariations: this.variations.length > 0,
      stickyName: false,
      skipFontColorCustomization:
        this.$store.getters.customizationMenuDarkMode && this.inSearchPage,
      enableMenuItemsDivider:
        this.$store.getters.customizationMenuItemsDivider && !this.inSearchPage,
      skipFirstItemDivider: this.$store.getters
        .customizationMenuItemsDividerSkipFirst,
    };
  },
  computed: {
    descriptionComputed() {
      return normalizeNewLines(this.description);
    },
    priceComputed() {
      return this.discounted_price !== false
        ? this.discounted_price
        : this.price;
    },
    oldPriceComputed() {
      return this.price;
    },
    variationsLowPrice() {
      return Math.min(
        ...this.variations.map(function(variation) {
          return variation.price;
        })
      );
    },
    variationsHighPrice() {
      return Math.max(
        ...this.variations.map(function(variation) {
          return variation.price;
        })
      );
    },
    variationsLoyaltyLowPrice() {
      return Math.min(
        ...this.variations
          .filter(function(variation) {
            return variation.loyalty_price;
          })
          .map(function(variation) {
            return variation.loyalty_price;
          })
      );
    },
    variationsLoyaltyHighPrice() {
      return Math.max(
        ...this.variations.map(function(variation) {
          return variation.loyalty_price;
        })
      );
    },
    loyaltyPricingEnabled() {
      return (
        this.$store.state.restaurant.loyaltyPricingEnabled &&
        (this.loyalty_price ||
          this.variations.filter(function(variation) {
            return variation.loyalty_price;
          }).length > 0)
      );
    },
    menuItemIsInCart() {
      if (!this.$store.getters.functionalityOrderEnabled) {
        return false;
      }

      return (
        this.$store.state.cart.items.filter(orderItem => {
          return orderItem.item_id === this.id;
        }).length > 0
      );
    },
    cartQuantity() {
      let quantity = 0;
      this.$store.state.cart.items.forEach(orderItem => {
        if (orderItem.item_id === this.id) {
          quantity += orderItem.quantity;
        }
      });
      return quantity;
    },
    dishTagsDefaultAndCustomCombined() {
      return [...this.tags, ...this.restaurant_tags];
    },
  },
  methods: {
    openSheet() {
      this.trackMenuItemClick();
      this.$store.dispatch('openMenuItem', this.id);
    },
    closeSheet() {
      // this.sheet = false;
    },
    trackMenuItemView(entries) {
      if (entries[0].isIntersecting && entries[0].intersectionRatio === 1) {
        if (!this.$store.state.analyticsViewedItems.includes(this.id)) {
          // this.axios.post(`/analytics/menu-item-view`, {
          //   'session': this.$store.state.session,
          //   'menu_item_id': this.id
          // })
          this.$store.dispatch('addToAnalyticsViewedItem', this.id);
        }
      }
    },
    trackMenuItemClick() {
      if (!this.$store.state.analyticsClickedItems.includes(this.id)) {
        // this.axios.post(`/analytics/menu-item-click`, {
        //   'session': this.$store.state.session,
        //   'menu_item_id': this.id
        // })
        this.$store.dispatch('addToAnalyticsClickedItem', this.id);
      }
    },
    onSheetProductNameIntersect(entries) {
      if (entries[0].intersectionRatio < 0.1 && this.sheet) {
        this.stickyName = true;
      } else {
        this.stickyName = false;
      }
    },
    onScroll(e) {
      const collapsingTitle = e.target.querySelector(
        '.menu-item-sheet__top-collapsing-name-wrapper'
      );

      if (this.stickyName) {
        collapsingTitle.classList.add('sticky');
      } else {
        collapsingTitle.classList.remove('sticky');
      }
    },
  },
};
</script>

<style lang="scss">
.menu-item {
  display: flex;
  margin: 0 0 24px;
  position: relative;
  align-items: flex-start;

  &:last-child {
    padding: 0;
    margin-bottom: 0;
  }

  justify-content: space-between;

  &__info {
    width: 58%;
    display: flex;
    flex-direction: column;
    text-align: left;

    &--full-width {
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__image {
    position: relative;
    width: 42%;
    display: flex;
    align-items: center;
    img {
      border-radius: 5px;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1.5;
      max-height: 220px;
      @media only screen and (max-width: 700px) {
        max-height: 170px;
      }

      @media only screen and (max-width: 500px) {
        max-height: 120px;
      }

      @media only screen and (max-width: 420px) {
        max-height: 100px;
      }
    }

    .v-image {
      border-radius: 5px;
    }
  }

  &--in-cart {
    &::before {
      border-left: 5px solid var(--color-primary);
      content: '';
      position: absolute;
      left: -16px;
      top: 0;
      width: 5px;
      height: 100%;
    }
  }

  &--out-of-stock {
    .lazy-img,
    .menu-item__info {
      opacity: 0.5 !important;
    }
  }

  &--skip-font-color-customization {
    .menu-item-info__title {
      color: #000 !important;
    }
    .menu-item-info__description {
      color: $color-gray !important;
    }
  }

  &--with-divider {
    border-top: 1px solid var(--menu-items-divider-color);
    padding-top: 12px !important;
    margin: 0 0 12px;
    &--not-first:first-child {
      border-top: none;
    }
  }
}

.menu-item-info {
  &__title-wrapper {
    display: flex;
    gap: 8px;
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: var(--menu-items-title-line-height);
    max-height: var(--menu-items-title-max-height);
    font-family: var(--menu-font-family) !important;
    font-weight: var(--menu-items-font-weight) !important;
    color: var(--menu-items-font-color) !important;
    font-size: var(--menu-items-font-size) !important;
    font-style: var(--menu-items-font-style) !important;
    letter-spacing: var(--menu-items-title-letter-spacing) !important;
  }

  &__order-quantity-indicator {
    background-color: var(--color-primary);
    border-radius: 3px;
    width: 22px;
    min-width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__description {
    position: relative;
    color: var(--menu-short-description-color);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    line-height: 19px !important;
    margin-top: 3px;
    font-size: var(--menu-short-description-size) !important;
    font-family: var(--menu-item-description-font-family) !important;
    font-style: var(--menu-item-description-font-style) !important;
    white-space: pre-line !important;
    font-weight: var(--menu-short-description-weight) !important;
    letter-spacing: var(--menu-item-description-letter-spacing) !important;
  }

  &__price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;

    &--align-end {
      align-items: flex-end;
      text-align: right;
      text-indent: -1em;
    }

    &--align-self-center {
      align-self: center;
    }
  }
}

.menu-item__info--full-width {
  .menu-item-info__title {
    -webkit-line-clamp: 2;
  }

  .menu-item-info__description {
    -webkit-line-clamp: 4;
  }
}

.loyalty-pricing-disclaimer {
  color: gray;
}

.v-image__image--preload {
  filter: blur(10px);
}
</style>
